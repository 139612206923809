import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

interface IModalProps {
  modalIsOpen: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  message: string;
  titleMessage?: string;
  typeMessage: 'error' | 'sucess';
}

export function ModalInformation({ modalIsOpen, closeModal, message, typeMessage, titleMessage }: IModalProps) {
  const handleModal = () => {
    closeModal(true);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModal}
      ariaHideApp={false}
      style={{
        overlay: {
          width: '100%',
          zIndex: 9999999,
          margin: 'auto',
        },
        content: {
          maxWidth: '758px',
          maxHeight: '200px',
          width: '80%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          borderRadius: '15px',
          margin: '100px auto',
          padding: '20px',
        },
      }}
    >
      <ContainerInformation>
        {titleMessage && <TitleInformationMessage typeError={typeMessage}>{titleMessage}</TitleInformationMessage>}
        <InformationMessage typeError={typeMessage}>{message}</InformationMessage>
        <ClosingTimeBar>
          <div className="progress"></div>
        </ClosingTimeBar>
      </ContainerInformation>
    </Modal>
  );
}

const ContainerInformation = styled.div`
  max-width: 758px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  /* padding: 10px; */

  h2 {
    color: #0076a0;
    font-family: 'P22 Mackinac Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    margin-top: 10px;
  }
`;

const TitleInformationMessage = styled.h1<{ typeError: string }>`
  color: ${(props) => (props.typeError === 'error' ? '#e36387 !important' : '#5cc9aa ')};
  font-size: 25px !important;
  text-align: center;
`;

const InformationMessage = styled.h2<{ typeError: string }>`
  color: ${(props) => (props.typeError === 'error' ? '#e36387 !important' : '#5cc9aa ')};
  font-size: 24px !important;
  text-align: center;
`;

const ClosingTimeBar = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 5px;
  left: 0;
  background-color: #ddd;

  .progress {
    background-color: #e36387;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 5px;
    animation: progresso 5s ease-in 1;

    @keyframes progresso {
      0% {
        width: 0;
      }
      10% {
        width: 10%;
      }
      20% {
        width: 20%;
      }
      30% {
        width: 30%;
      }
      40% {
        width: 40%;
      }
      50% {
        width: 50%;
      }
      60% {
        width: 60%;
      }
      70% {
        width: 70%;
      }
      80% {
        width: 80%;
      }
      90% {
        width: 90%;
      }
      100% {
        width: 100%;
      }
    }
  }
`;
