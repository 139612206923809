import React, { useState } from 'react';
import styled from 'styled-components';

import Accordion from 'components/Accordion';

import { questions } from 'utils/commomQuestions';

export default function FaqSection() {
  const [showQuestions, setShowQuestions] = useState(12);


  const handleLoadMore = () => {
    setShowQuestions(showQuestions + 12);
    };
  
  const handleLoadLess = () => {
    setShowQuestions(12);
  };

return (
<Wrapper>
  {questions.slice(0, showQuestions).map(question => (
    <ItemFaq key={question.id} title={question.text}>
      <span dangerouslySetInnerHTML={{ __html: question.description ? question.description : '' }} />
    </ItemFaq>
  ))}
  {showQuestions > 30 && 
    <InfoMessage>
    <strong>Caso não tenha encontrado aqui a resposta </strong>
    adequada para a sua pergunta, entre em contato conosco, através da aba “Contato” que fica no menu superior do site ou através do nosso e-mail contato@meunascimento.com.br.<br/><br/>
    Descreva seu problema e sua pergunta. Será um prazer te ajudar!<br/><br/>
    Atenciosamente,<br/><br/>
    Equipe Meu Nascimento.
    </InfoMessage>
  }
  <ButtonLoadList onClick={showQuestions < 30 ? handleLoadMore : handleLoadLess}>
    {showQuestions < 30 ? "Ver mais perguntas" : "Ver menos"}
  </ButtonLoadList>
</Wrapper>
);
}

const Wrapper = styled.div`
  margin-top: 30px;
  & > *:not(:first-child) {
   margin-top: 2rem;
  }
  

  
  div {

    background-color: transparent !important;
    color: #fff;
    max-width: 700px !important;

    h3 {
      border-bottom: 1px solid #fff;
    }
    span {
      width: 100%;
      max-width: 50px ;
    }
  }
  `;

  const ItemFaq = styled(Accordion)`
  background-color: transparent !important;
  background: transparent;
  color: #fff;
`;

const ButtonLoadList = styled.button`
  padding: 15px 45px;
  border-radius: 8px;
  text-transform: uppercase;
  border: none;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  margin-top: 20px;
  background-color: #8b8ee8;
  width: 100%;
  max-width: 415px;
  padding: 15px 48px;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  margin-top: 20px;
  border-radius: 8px;
  margin-right: 50px;
  margin: auto auto;
  cursor: pointer;

  & > a {
    font-family: Nunito;

    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }

  @media (max-width: 478px) {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 14px !important;
    width: 90%;
  }

  a {
    color: #fff6e8 !important;
  }
`;

const InfoMessage = styled.div`
  font-size: 18px;
  margin-left: 1.5rem;
`;