import { InferGetStaticPropsType } from 'next';
import { useCallback} from 'react';
import styled from 'styled-components';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import { SEO } from 'components/Seo';
import { useAuth } from 'contexts/AuthContext';
import { EnvVars } from 'env';
import HomePageDefault from 'views/HomePage/HomePageDefault';

type NavItem = {
  title: string;
  href: string;
  outlined?: boolean;
  [key: string]: any; 
};

type NavItems = NavItem[];

export default function Homepage({}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { isAuthenticated } = useAuth();

  const sendConversionEvent = useCallback(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {'send_to': 'AW-11161453973/nGxLCOTv4J4YEJWLmcop'});
    }
  }, []);

  const navItems: NavItems = [
    { title: 'Quem somos', href: '/about' },
    { title: 'Como funciona', href: '/faq' },
    { title: 'Contato', href: '/contact' },
    {
      title: !isAuthenticated ? 'Login' : 'Entrar',
      href: !isAuthenticated ? '/login' : '/profile/user',
      outlined: true,
      onClick: !isAuthenticated ? sendConversionEvent : undefined,
    },
  ];

  return (
    <>
      <SEO
        title={`${EnvVars.SITE_NAME} - Presentes para uma nova vida`}
        description="Receba os presentes do chá de bebê ou aniversário em dinheiro e use como quiser - com o melhor custo-benefício do mercado e 100% seguro."
        excludeTitleSuffix
        image={`${EnvVars.URL}/logo.png`}
      />
      <Navbar items={navItems} />
        <WhiteBackgroundContainer>
          <HomePageDefault />
        </WhiteBackgroundContainer>
        <Footer />
    </>
  );
}

const WhiteBackgroundContainer = styled.div`
  background-color: #fff6e8;
  font-display: swap;
`;

export async function getStaticProps() {
  return {
    props: {},
  };
}
