import React from 'react';
import styled from 'styled-components';

interface IStyleProps {
  image: string;
}

const SlideContainerLeft = styled.div<IStyleProps>`
  width: 250px;
  height: 350px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  float: left;
  margin-right: 25px;
  
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.9);
`;

const SlideContainerRight = styled.div<IStyleProps>`
  width: 380px;
  height: 600px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  float: right;
  margin-right: 25px;
  cursor: pointer;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.2);
`;

interface IProps {
  image: string;
  setSelectedImage: (src: string) => void;
  direction: 'left' | 'right';
}

const Slide = ({ image, setSelectedImage, direction }: IProps) => {
  return (
    <>
      {direction === 'left' ? (
        <SlideContainerLeft image={image} onClick={() => setSelectedImage(image)} />
      ) : (
        <SlideContainerRight image={image} onClick={() => setSelectedImage(image)} />
      )}
    </>
  );
};

export default Slide;
