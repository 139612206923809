export const KEY_WORDS = [
  'meu nascimento',
  'chá de bebê',
  'chá de fralda',
  'chá revelação',
  'aniversário',
  'presente',
  'gravidez',
  'gestação',
];
